import { Card, Col, Container, Image, Row, Stack } from "react-bootstrap";

import { useWindowSize } from "./hooks/useWindowSize";
import Navigation from "./components/navigation";



import IntroPicture from "./assets/graphics/photo-1661257454984-260701259b64.jpeg"
import AboutPicture from "./assets/graphics/photo-1661257454984-260701259b65.jpeg"
import Team01Picture from "./assets/graphics/photo-1522071820081-009f0129c71c.jpeg"
import Team02Picture from "./assets/graphics/photo-1518534543674-5933a2307dca.jpeg"
import MarketingPicture from "./assets/graphics/photo-1501556466850-7c9fa1fccb4c.jpeg"

import PlatformPicture from "./assets/graphics/photo-1623685463160-f3f501540a91.jpeg"
import EverythingScratchPicture from "./assets/graphics/photo-1549574518-8f791f8a16d4.jpeg"
import GoalPicture from "./assets/graphics/photo-1587480335344-95513e5cf3b7.jpeg"
import ContactPicture from "./assets/graphics/photo-1615598266497-4176fe2ecb94.jpeg"

import "bootstrap/dist/css/bootstrap.min.css";


const App = () => {

  const [width] = useWindowSize();

  const introductoryText = "BestiesPakLtd. is mainly focused on retailing the products. Your products are sold to best targeted consumers which not only benefits to wholesale dealers but also helps the consumers to reach their required commodity."
  const aboutUsText1 = "We are a group of technical and non-technical professionals with core concern of moving from traditional retailing into digital world. We have experience of working as retailer but now we want to increase the scope by joining the online platforms."
  const aboutUsText2 = "We are enthusiastic for helping our consumers to get their required product and also the wholesale dealers/manufacturers in growing their revenue."
  const ourTeam1 = "Our team is comprised of different expertise in their own field. We have technical people who are responsible for maintaining the technical side of sales, online-platforms handling, product listing, providing support to customers."
  const ourTeam2 = "While on the other hand we also have business people who are responsible for dealing with wholesale dealers and manufacturers to keep separation of concerns. We work all together to make this business process work smoothly and affectively."
  const marketingStrategy = "Our marketing strategy is combination of various techniques. The foremost way is to target the audience by using digital platforms e-g PPC, social media marketing and email marketing."
  const businessPlan = "Our business plan is to get in the market by using some online platforms and proceed further to as many platforms as possible. Currently, we are focused on Amazon FBA, after that we will move to other famous platforms but at the end we have plans to work with our own website."
  const ownPlatform = "We have in-house capabilities of online-platforms handling and development, so it is not too far to work with our own e-commerce platform. "
  const everyThingStarsFromScratch = "The biggest challenge is to make the first step. We made the first step to get into the market, to increase the scope of our business and support others to grow their businesses."
  const goalOfRetailing = "As a retail startup we want to offer a new way of shopping to the end customers. We want to redefine the way we shop and offer new solutions to old problems. We will use technology to create a better customer experience for shoppers, to offer consumers something that was not previously available or was difficult to access before."
  const contactUs = "If you have any questions, feel free to contact through any of the way given below."

  return (
    <div>
      <Navigation />

      <Container fluid={"xxl"} className="p-0" id="home">
        <Row className="g-0 align-items-stretch section">
          <Col md={{ span: 6, order: 1 }} xs={{ order: 2 }}>
            <div className="background">
              <Image
                loading="lazy"
                className="banner-image"
                src={IntroPicture}
              />
            </div>
            <div className="py-5 description bg-grayish d-md-none">
              <Card.Text
                className={`text-bluish py-5 ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                  }`}
              >
                {introductoryText}
              </Card.Text>
            </div>
          </Col>
          <Col md={{ span: 6, order: 2 }} xs={{ order: 1 }}>
            <Stack className="h-100">
              <div className="flex-fill mt-5 py-5 description">
                <Card.Text className="text-capitalize h2 fw-normal">
                  Hi there!
                </Card.Text>
                <Card.Title
                  as={"h1"}
                  className={`text-uppercase fw-bold ${width >= 992
                    ? "display-1"
                    : width > 768
                      ? "display-2"
                      : "display-3"
                    }`}
                >
                  Welcome to Besties Pak LTD
                </Card.Title>
                <Card.Subtitle className="mb-5 fs-2 fw-normal">
                  We are here to help your business grow
                </Card.Subtitle>
              </div>
              <div className="py-5 description bg-grayish d-md-block d-none">
                <Card.Text
                  className={`text-bluish py-5 ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {introductoryText}
                </Card.Text>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>

      <Container fluid={"xxl"} className="p-0" id="about">
        <Row className="g-0 section">
          <Col md={6} className="align-self-end">
            <Stack>
              <div className="py-5 description">
                <Card.Title
                  as={"h1"}
                  className={`text-uppercase ${width >= 992
                    ? "display-1"
                    : width > 768
                      ? "display-2"
                      : "display-3"
                    } mb-5 fw-bold`}
                >
                  About Us
                </Card.Title>
                <Card.Text
                  className={`${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {aboutUsText1}
                </Card.Text>
                <Card.Text
                  className={`mb-lg-5 pb-lg-5 ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {aboutUsText2}
                </Card.Text>
              </div>
            </Stack>
          </Col>
          <Col md={6}>
            <div className="background">
              <Image
                loading="lazy"
                className="banner-image"
                src={AboutPicture}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid={"xxl"} className="d-md-block d-none p-0">
        <Row className="g-0 section">
          <Col xs={12}>
            <div className="ratio ratio-21x9">
              <Image
                loading="lazy"
                className="banner-image"
                src={Team01Picture}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid={"xxl"}
        className="overflow-hidden position-relative"
        id="team"
      >
        <Row className="g-0 section align-items-stretch justify-content-center">
          <Col lg={5} md={6}>
            <Stack className="ps-xl-5">
              <div className="py-5 description px-0">
                <Card.Title
                  as={"h1"}
                  className={`text-uppercase ${width >= 992
                    ? "display-1"
                    : width > 768
                      ? "display-2"
                      : "display-3"
                    } mb-5 fw-bold`}
                >
                  Our Team
                </Card.Title>
                <Card.Text
                  className={`${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {ourTeam1}
                </Card.Text>
                <div className="d-md-none my-4">
                    <div className="ratio ratio-21x9">
                      <Image
                        loading="lazy"
                        className="banner-image"
                        src={Team01Picture}
                      />
                    </div>
                </div>
                <Card.Text
                  className={`mb-lg-5 pb-lg-5 ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {ourTeam2}
                </Card.Text>
              </div>
            </Stack>
          </Col>
          <Col xs={12}>
            <div className="ratio ratio-21x9">
              <Image
                loading="lazy"
                className="banner-image"
                src={Team02Picture}
              />
            </div>
          </Col>
        </Row>
        <div
          className="position-absolute top-0 start-50 h-75"
          style={{
            width: 450,
            zIndex: -1,
            backgroundColor: "rgba(255,255,255, 0.15)",
          }}
        ></div>
      </Container>

      <div className="mt-5 overflow-hidden position-relative">
        <div
          className={`position-absolute top-0 w-100 ${width > 768 ? "" : "ratio ratio-16x9"
            } bg-white`}
          style={{ zIndex: -1, height: width > 768 ? "15%" : "" }}
        ></div>
      </div>

      <Container
        fluid={"xxl"}
        className="p-0 overflow-hidden position-relative"
        id="business"
      >
        <div className="position-absolute top-0 bottom-0 start-0 end-0 d-md-block d-none">
          <Image
            loading="lazy"
            className="banner-image"
            style={{ transform: "scale(-1)" }}
            src={MarketingPicture}
          />
        </div>
        <Row className="g-0 section">
          <Col md={6}>
            <Card className="border-0 shadow-0 rounded-0 bg-transparent">
              <Card.Header className="bg-white">
                <Card.Title
                  as={"h1"}
                  className={`text-center text-uppercase ${width >= 1440 ? "display-5" : width <= 768 ? "fs-3" : "fs-1"
                    } py-4 fw-bold`}
                >
                  MARKETING STRATEGY
                </Card.Title>
              </Card.Header>
              <Card.Body className="py-5 px-md-5 bg-bluish">
                <Card.Text
                  className={`py-5 text-white ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {marketingStrategy}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="border-0 shadow-0 rounded-0 bg-transparent">
              <Card.Header className="bg-white">
                <Card.Title
                  as={"h1"}
                  className={`text-center text-uppercase ${width >= 1440 ? "display-5" : width <= 768 ? "fs-3" : "fs-1"
                    } py-4 fw-bold`}
                >
                  BUSINESS OVERALL PLAN
                </Card.Title>
              </Card.Header>
              <Card.Body className="py-5 px-md-5 bg-bluish">
                <Card.Text
                  className={`py-5 text-white ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {businessPlan}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className={"d-md-none"}>
            <div className="background">
              <Image
                loading="lazy"
                className="banner-image"
                style={{ transform: "scale(-1)" }}
                src={MarketingPicture}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid={"xxl"} className={"py-5 mt-5 overflow-hidden"}>
        <Row className="g-xl-5 g-lg-4 g-sm-3 g-0 section">
          <Col xs={12}>
            <Card className="bg-transparent my-5 mx-lg-5 mx-3 rounded-0 border-0">
              <Row className="g-0">
                <Col lg={4} md={5}>
                  <div className="background">
                    <Image
                      loading="lazy"
                      className="banner-image"
                      src={PlatformPicture}
                    />
                  </div>
                </Col>
                <Col md className="align-self-center">
                  <Card.Body className="py-5 px-lg-5 px-md-4">
                    <Card.Text
                      className={`text-white ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                        }`}
                    >
                      {ownPlatform}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12}>
            <Card className="bg-transparent my-5 mx-lg-5 mx-3 rounded-0 border-0">
              <Row className="g-0">
                <Col
                  md={{ order: 1 }}
                  xs={{ order: 2 }}
                  className="align-self-center"
                >
                  <Card.Body className="py-5 px-lg-5 px-md-4">
                    <Card.Text
                      className={`text-white ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                        }`}
                    >
                      {everyThingStarsFromScratch}
                    </Card.Text>
                  </Card.Body>
                </Col>
                <Col lg={4} md={{ span: 5, order: 2 }}>
                  <div className="background">
                    <Image
                      loading="lazy"
                      className="banner-image"
                      src={EverythingScratchPicture}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} className={"bg-white"}>
            <Card className="bg-transparent my-5 mx-lg-5 mx-3 rounded-0 border-0">
              <Row className="g-0">
                <Col lg={4} md={5}>
                  <div className="background">
                    <Image
                      loading="lazy"
                      className="banner-image"
                      src={GoalPicture}
                    />
                  </div>
                </Col>
                <Col md className="align-self-center">
                  <Card.Body className="py-5 px-lg-5 px-md-4">
                    <Card.Text
                      className={`text-bluish ${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                        }`}
                    >
                      {goalOfRetailing}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="mt-5 overflow-hidden position-relative pb-5">
        <div
          className={`position-absolute bottom-0 w-100 h-50 bg-white`}
          style={{ zIndex: -1 }}
        ></div>
        <Container fluid={"xxl"} className={"py-5"}>
          <Row className="g-0 align-items-stretch justify-content-center mt-lg-0 mt-sm-5">
            <Col xs={12}>
              <div className="ratio ratio-1x1">
                <Image
                  loading="lazy"
                  className="banner-image"
                  src={ContactPicture}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container
        fluid={"xxl"}
        className="my-5 overflow-hidden position-relative"
        id="contact"
      >
        <Row className="g-0 section align-items-stretch justify-content-center py-5">
          <Col xs={12}>
            <Card className="p-md-5 bg-transparent border-0 shadow-none text-center">
              <Card.Body className="py-md-5 description px-0">
                <Card.Title
                  as={"h1"}
                  className={`text-uppercase ${width >= 992
                    ? "display-1"
                    : width > 768
                      ? "display-2"
                      : "display-3"
                    } mb-5 fw-bold`}
                >
                  LOOKING FORWARD TO YOU
                </Card.Title>
                <Card.Text
                  className={`${width >= 992 ? "fs-5" : width > 768 ? "" : "fs-6"
                    }`}
                >
                  {contactUs}
                </Card.Text>

                <div className="row text-center">
                  <div className="col-md-4">
                    <div className="bg-primary rounded-circle text-white mb-2 d-inline-block p-2" style={{ width: 32, height: 32, lineHeight: '12px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                      </svg>
                    </div>
                    <p> 15 Offa road, Bedford, England</p>
                  </div>
                  <div className="col-md-4">
                    <a href="tel:+44 7448 672432">
                      <div className="bg-primary rounded-circle text-white mb-2 d-inline-block p-2" style={{ width: 32, height: 32, lineHeight: '12px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                      </div>
                    </a>
                    <p>+44 7448 672432</p>
                  </div>
                  <div className="col-md-4">
                    <a href="mailto: sales@bestiespak.co.uk">
                      <div className="bg-primary rounded-circle text-white mb-2 d-inline-block p-2" style={{ width: 32, height: 32, lineHeight: '12px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                        </svg>
                      </div>
                    </a>
                    <p>sales@bestiespak.co.uk</p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};


export default App;
