import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import siteLogo from "./../assets/logos/site-logo.png";

const Navigation = () => {
    return (
        <Navbar collapseOnSelect bg='white' className='shadow-sm' sticky='top' expand="lg" variant="light">
            <Container fluid>
                <Navbar.Brand href="/">
                    <Image fluid src={siteLogo} alt="Besties Pak LTD Official Logo" width={48} /> &nbsp;
                    Besties Pak LTD
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="top-portfolio-navbar" />
                <Navbar.Collapse id="top-portfolio-navbar">
                    <Nav className='ms-auto'>
                        <Nav.Link href="/#home">
                            Home
                        </Nav.Link>
                        <Nav.Link href="/#about">
                            About
                        </Nav.Link>
                        <Nav.Link href="/#team">
                            Our Team
                        </Nav.Link>
                        <Nav.Link href="/#business">
                            Business Model
                        </Nav.Link>
                        <Nav.Link href="/#contact">
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;